import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrosoft, faAndroid, faAppStoreIos, faApple } from '@fortawesome/free-brands-svg-icons';

export class DownloadItem {
    alt: string = '';
    src: string = '';
    text: string = '';
    linkWin: string = '';
    linkMac: string = '';
    linkAndroid: string = '';
    linkIOS: string = '';

    constructor(alt: string, src: string, text: string, linkWin: string, linkMac: string, linkAndroid: string, linkIOS: string) {
        this.alt = alt;
        this.src = src;
        this.text = text;
        this.linkWin = linkWin;
        this.linkMac = linkMac;
        this.linkAndroid = linkAndroid;
        this.linkIOS = linkIOS;
    }
}

interface DownloadsProperties {
    downloads: DownloadItem[];
}

export default class DownloadPageItems extends React.Component<DownloadsProperties> {
    render() {
        return (
            <> {this.props.downloads.map(this.makeDI)} </>
        );
    }

    private makeDI(di: DownloadItem) {
        return (
            <Col xs={{ span: 11, offset: 1 }} sm={{ span: 11, offset: 1 }} md={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} xl={{ span: 6, offset: 0 }}>
                <Card className="bg-secondary m-2">
                    <Card.Header>
                        <img alt={di.alt} src={di.src} className='w-100' />
                    </Card.Header>
                    <Card.Body>
                        <Card.Text><small>{di.text} <span className="text-warning">Adobe Flash је онемогућен после 31.12.2020. тако да .swf апликације нису више подржане!</span></small></Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <Row>
                            <Col><Card.Link href={di.linkWin} className='btn btn-primary btn-block btn-sm' target={ di.linkWin.includes('javascript') ? '_self' : '_blank' } rel="noopener noreferrer"><FontAwesomeIcon icon={faMicrosoft}/></Card.Link></Col>
                            <Col><Card.Link href={di.linkMac} className='btn btn-primary btn-block btn-sm' target={ di.linkMac.includes('javascript') ? '_self' : '_blank' } rel="noopener noreferrer"><FontAwesomeIcon icon={faApple}/></Card.Link></Col>
                            <Col><Card.Link href={di.linkAndroid} className='btn btn-primary btn-block btn-sm' target={ di.linkAndroid.includes('javascript') ? '_self' : '_blank' } rel="noopener noreferrer"><FontAwesomeIcon icon={faAndroid}/></Card.Link></Col>
                            <Col><Card.Link href={di.linkIOS} className='btn btn-primary btn-block btn-sm' target={ di.linkIOS.includes('javascript') ? '_self' : '_blank' } rel="noopener noreferrer"><FontAwesomeIcon icon={faAppStoreIos}/></Card.Link></Col>
                        </Row>
                    </Card.Footer>
                </Card>
            </Col>
        );
    }
}