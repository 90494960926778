import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnity, faMicrosoft, faWindows, faLinux, faApple } from '@fortawesome/free-brands-svg-icons';
import { faServer, faFileCode, faDatabase } from '@fortawesome/free-solid-svg-icons';
import faAdobe from '../../images/adobe.png';

export default class HomePage extends React.Component {
    render() {
        return (
            <>
            <Card className="bg-secondary m-2">
                <Card.Header>Укратко о мени</Card.Header>
                <Card.Body>
                  <Card.Text>
                  Поздрав свима, ово је мој лични портфолио сајт. Ја сам дипломирани инжењер софтвера (дипломирао сам 2022 на смеру Софтверско и информационио инжењерство на Универзитету Сингидунум).
                  Тренутно сам запослен на позицији Јуниор програмера (енг. Junior Solution Developer) у фирми Engineering Software Lab D.O.O.
                  </Card.Text>
                  <Card.Text>Имам искуства у коришћењу следећих алата:</Card.Text>
                  <span><img alt="Adobe logo" src={ faAdobe } /> Animate/Photoshop/Premiere/After Effects/Media Encoder/Dreamweaver - средњи ниво.</span><br></br>
                  <span><FontAwesomeIcon icon={ faUnity } /> Упознат сам са кључним концептима програмирања 2D/3D игара. Тренутно изучавам AR/VR технологије.</span><br></br>
                  <span><FontAwesomeIcon icon={ faMicrosoft } /> Office пакет (Word, Excel, Powerpoint, Access) и Visual Studio/VS Code</span><br></br>
                  <span><FontAwesomeIcon icon={ faServer } /> Имам широко искуство у инсталацији оперативних система и њиховом одржавању (<FontAwesomeIcon icon={faWindows} />  <FontAwesomeIcon icon={faLinux} /> <FontAwesomeIcon icon={faApple} />) и одржавању хардвера рачунара, рачунарске мреже (основно око сервера).</span><br></br>
                  <span><FontAwesomeIcon icon={ faFileCode } /> Имам искуства у следећим програмским језицима/технологијама: C/C++, C#, Java (Android Studio, Spring), Swift (xCode, iOS), HTML, CCS, JavaScript, Typescript (Angular, React), PHP, ActionScript 3 (за Adobe Flash/Animate), Python (Jupiter notebook), R.</span><br></br>
                  <span><FontAwesomeIcon icon={ faDatabase } /> Такође сам упознат са следећим базама података: mySQL (Navicat, phpMyAdmin), Oracle (Application Express - APEX), NoSQL (MongoDB)</span>
                  <Card.Text>
                  <br></br>Кликом на други линк у менију лево биће Вам доступне моје апликације и анимације 
                  за преузимање. А кликом на трећи игрица Тајни агент изи.
                  </Card.Text>
                </Card.Body>
            </Card>
            <Card className="bg-secondary m-2">
                <Card.Header>О креирању сајта и важна напомена за линкове</Card.Header>
                <Card.Body>
                  <Card.Text>
                    Сајт је креиран коришћењем Bootstrap оквира (енг. framework) и React (JavaScript) библиотеке. 
                    Сличице су бесплатне (Font Awesome). Мени са стране је преузет <a href='https://azouaoui-med.github.io/react-pro-sidebar/' className='text-decoration-none text-dark' target='_blank' rel="noopener noreferrer">одавде</a>.<br></br>
                    <b>Сви линкови воде ка adf.ly рекламама, притисните SkipAd (прескочите рекламу) док не дођете до сајт-а за преузимање (mega.nz,Google Drive,...). НИСАМ ОДГОВОРАН за садржаје које пружа adf.ly и UnityAds и уколико они оштете Ваш уређај!</b>
                  </Card.Text>
                </Card.Body>
            </Card>
            </>
        );
    }
}