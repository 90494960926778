import './index.css';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.js';
import 'popper.js/dist/popper.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import React from 'react';
import ReactDOM from 'react-dom';
import HomePage from './components/HomePage/HomePage';
import DownloadsPage from './components/DownloadsPage/DownloadsPage';
import SecretAgentIzzyPage from './components/SecretAgentIzzyPage/SecretAgentIzzyPage';
import gasStationWebGL from './components/DownloadsPage/gasStationWebGL';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as fs_icons from '@fortawesome/free-solid-svg-icons/';
import * as fb_icons from '@fortawesome/free-brands-svg-icons/';
import { ProSidebar, SidebarFooter, SidebarContent, Menu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import ProSideBarItems, { ProSideBarItem } from './components/ProSideBarItems/ProSideBarItems';

const items = [
  new ProSideBarItem('Почетна страница', fs_icons.faHome, '/', '_top'),
  new ProSideBarItem('Игрице и анимације', fs_icons.faFileDownload, '/gamesAndAnimations', '_top'),
  new ProSideBarItem('Тајни агент изи', fb_icons.faUnity, '/secretAgentIzzy', '_top'),
  new ProSideBarItem('Е-мејл', fs_icons.faEnvelopeSquare, { pathname: 'mailto:marko.dojkic@gmail.com' }, '_blank'),
  new ProSideBarItem('Facebook', fb_icons.faFacebookSquare, { pathname: 'http://tenteaea.com/2y6V' }, '_blank'),
  new ProSideBarItem('Youtube', fb_icons.faYoutubeSquare, { pathname: 'http://tenteaea.com/2yA9' }, '_blank'),
  new ProSideBarItem('LinkedIn', fb_icons.faLinkedin, { pathname: 'http://j.gs/DuRz' }, '_blank'),
  new ProSideBarItem('GitHub', fb_icons.faGithubSquare, { pathname: 'http://j.gs/DuS3' }, '_blank'),
];

ReactDOM.render(
  <React.StrictMode>
    <div className='container'>
      <ProSidebar className={window.innerWidth < 1000 ? 'collapsed' : ''}>
        <SidebarContent>
          <Menu iconShape='circle' className='font-weight-bolder'>
            <ProSideBarItems items={items}></ProSideBarItems>
          </Menu>
        </SidebarContent>
        <SidebarFooter className='text-center'>© 2023 Марко Дојкић</SidebarFooter>
      </ProSidebar>
      <main id='main'>
        <BrowserRouter>
          <Switch>
            <Route exact path='/' component={HomePage} />
            <Route path='/gamesAndAnimations' component={DownloadsPage} />
            <Route path='/secretAgentIzzy' component={SecretAgentIzzyPage} />
            <Route path='/gasStationWebGL' component={gasStationWebGL} />
          </Switch>
        </BrowserRouter>
      </main>
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();