import React from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { MenuItem } from 'react-pro-sidebar';

export class ProSideBarItem {
    text: string = '';
    faImage?: IconDefinition | undefined;
    link: any;
    target: string = '';

    constructor(text: string, faImage: IconDefinition | undefined, link: any, target: string){
        this.text = text;
        this.faImage = faImage;
        this.link = link;
        this.target = target;
    }
}

interface ProSideBarItemsProperties {
    items: ProSideBarItem[];
}

export default class ProSideBarItems extends React.Component<ProSideBarItemsProperties> {
    render() {
        return (
            this.props.items.map(this.makeLink)
        );
    }

    private makeLink(item: ProSideBarItem){
        return (
            <MenuItem icon={ 
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d={item.faImage?.icon[4].toString()} ></path></svg> }>
                    <BrowserRouter><Link to={ item.link } className='nav-link' target={ item.target }>{item.text}</Link></BrowserRouter>
            </MenuItem>
        );
    }
}