import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import DownloadPageItems, { DownloadItem } from '../DownloadPageItems/DownloadPageItems';
import benzinskapumpa_ss from '../../images/benzinska_pumpa.gif';
import busplus_ss from '../../images/bus_plus.gif';
import lososball_ss from '../../images/lososball.gif';
import telefonskagovornica_ss from '../../images/telefonska_govornica.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAndroid, faJava } from '@fortawesome/free-brands-svg-icons';

const downloads = [
    new DownloadItem('busplus_slideshow_md',busplus_ss,'БусПлус - Апликција направљена по узору на платни систем (верзија 2014). Урађена је у Adobe Flash CC 2014. Апликација је доступна за Windows и Android.', 'http://j.gs/EbL7', 'javascript:alert("НИЈЕ ДОСТУПНО!")', 'http://q.gs/FFTBP', 'javascript:alert("НИЈЕ ДОСТУПНО!")'),
    new DownloadItem('lososball_slideshow_md',lososball_ss,'Lososball™ - Моја прва игрица. Урађена је у Adobe Flash CC 2014. Апликација је доступна за Windows.', 'http://q.gs/FFTBa', 'javascript:alert("НИЈЕ ДОСТУПНО!")', 'javascript:alert("НИЈЕ ДОСТУПНО!")', 'javascript:alert("НИЈЕ ДОСТУПНО!")'),
    new DownloadItem('telefonskagovornica_slideshow_md',telefonskagovornica_ss,'Телефонска говорница - цртана интерактивна прича о двоје пријатеља (глас и анимације: Марко Дојкић  ). Урађена је у Adobe Flash CC 2014. Апликација је доступна за Windows.', 'http://q.gs/FFTBt', 'javascript:alert("НИЈЕ ДОСТУПНО!")', 'javascript:alert("НИЈЕ ДОСТУПНО!")', 'javascript:alert("НИЈЕ ДОСТУПНО!")'),
];

export default class GamesAndAnimationsPage extends React.Component {
    componentDidMount() {
        document.title = 'Игрице и анимације'
    }

    render() {
        return (
            <Row>
                <Col xs={{ span: 11, offset: 1 }} sm={{ span: 11, offset: 1 }} md={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} xl={{ span: 6, offset: 0 }}>
                    <Card className="bg-secondary m-2">
                        <Card.Header>
                            <img alt='benzinska_pumpa_slideshow_md' src={ benzinskapumpa_ss } className='w-100' />
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>Бензинска пумпа - Једна од мојих првих апликација. Урађена је у Adobe Flash CC 2014. Преправљена у Unity-ју за WebGL.</Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Card.Link href='/gasStationWebGL' className='btn btn-primary btn-block'>Покрени игрицу</Card.Link>
                        </Card.Footer>
                    </Card>
                </Col>
                <DownloadPageItems downloads={ downloads }></DownloadPageItems>
                <Col xs={{ span: 11, offset: 1 }} sm={{ span: 11, offset: 1 }} md={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} xl={{ span: 12, offset: 0 }}>
                    <Card className="bg-secondary m-2">
                        <Card.Header>
                            Остале апликације и анимације
                        </Card.Header>
                        <Card.Body>
                            <Card.Text><small>Овде можете преузети моје анимације и остале апликације. Доступне су у .swf формату (креиране 2013/14.) <span className="text-warning">Adobe Flash је онемогућен после 31.12.2020. тако да .swf апликације нису више подржане!</span></small></Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Row>
                                <Col><Card.Link href={require('../../downloads/analogniSat.swf').default} className='btn btn-primary btn-block btn-sm' download>Аналогни сат</Card.Link></Col>
                                <Col><Card.Link href={require('../../downloads/filmskoOdbrojavanje.swf').default} className='btn btn-primary btn-block btn-sm' download>Филмско одбројавање</Card.Link></Col>
                                <Col><Card.Link href={require('../../downloads/rampa.swf').default} className='btn btn-primary btn-block btn-sm' download>Рампа</Card.Link></Col>
                                <Col><Card.Link href={require('../../downloads/semafor.swf').default} className='btn btn-primary btn-block btn-sm' download>Семафор</Card.Link></Col>
                            </Row>
                            <br></br>
                            <Row>
                            <Col><Card.Link href='http://q.gs/FFTIC' className='btn btn-primary btn-block btn-sm' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faAndroid}/> БусПлус 2.0.0</Card.Link></Col>
                                <Col><Card.Link href='http://q.gs/FFTIn' className='btn btn-primary btn-block btn-sm' target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faAndroid}/> Распоред звоњења за основну школу</Card.Link></Col>
                                <Col><Card.Link href={require('../../downloads/WarShips.jar').default} className='btn btn-primary btn-block btn-sm' download><FontAwesomeIcon icon={faJava}/> WarShips - Јава конзолна апликација</Card.Link></Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        );
    }
}