import React from 'react';

function iframe() {
    return {
        __html: '<iframe src="./BenzinskaPumpa/index.html" style=\'margin-top: 5%; width: 800px; height: 600px;\'></iframe>'
    }
}

export default class WebGL extends React.Component {

    componentDidMount() {
        document.title = 'Бензинска пумпа - Unity WebGL'
    }

    render() {
        return (
        <div>
            <div dangerouslySetInnerHTML={ iframe() } />
        </div>)
    } 
}

/* import Unity, { UnityContext } from "react-unity-webgl";

const unityContext = new UnityContext({
    loaderUrl: "src/downloads/BenzinskaPumpa/Build/521b70268c8fab9841964e1b75279d0c.js",
    dataUrl: "src/downloads/BenzinskaPumpa/Build/8303541481c27ec8704b094855aba1e4.data",
    frameworkUrl: "src/downloads/BenzinskaPumpa/Build/b4474266c6fed25476520c8e1c26b233.js",
    codeUrl: "src/downloads/BenzinskaPumpa/Build/7dc92f68025848ca4c1d65a17055be50.wasm",
    streamingAssetsUrl: "src/downloads/BenzinskaPumpa/StreamingAssets",
    companyName: "Марко Дојкић",
    productName: "Вензинска пумпа",
    productVersion: "2.0"
});

export default class WebGL extends React.Component {
    render() {
        return <Unity unityContext={unityContext} />;
    } 

    
} */

/*
    Ne radi greske:
    src/downloads/BenzinskaPumpa/Build/521b70268c8fab9841964e1b75279d0c.js:1 Uncaught SyntaxError: Unexpected token '<'

    unity.ts:80 Something went wrong while mouting the UnityInstance TypeError: window.createUnityInstance is not a function
    at t.<anonymous> (unity.ts:73)
    at 2.e48d65cd.chunk.js:2
    at Object.next (2.e48d65cd.chunk.js:2)
    at a (2.e48d65cd.chunk.js:2)
*/